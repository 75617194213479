<template>
  <div class="orderDetail" style="text-align: left;">
    <van-nav-bar
        title="订单详情"
        left-text="返回"
        left-arrow
        @click-left="onReturn">
    </van-nav-bar>

    <van-cell v-if="isReplaceFlag" value="明细信息(换出)" style="margin-top: 10px;font-weight: 550;font-size: 15px;" />
    <van-cell-group v-if="isReplaceFlag">
      <van-cell v-for="(item,index) in replaceDetailList" :key="index" :title="item.commodityName" :value="'￥'+item.commodityPrice+'×'+item.amount" />
    </van-cell-group>
    <van-cell :value="isReplaceFlag?'明细信息(换入)':'明细信息'" style="margin-top: 10px;font-weight: 550;font-size: 15px;" />
    <van-cell-group>
      <van-cell v-for="(item,index) in targetDetailList" :key="index" :title="item.commodityName" :value="'￥'+item.commodityPrice+'×'+item.amount" />
    </van-cell-group>
    <van-cell v-if="payAmount != null" class="heji" title="合计" :value="'￥'+payAmount" />

    <van-cell value="订单信息" style="margin-top: 10px;font-weight: 550;font-size: 15px;" />
    <van-cell-group>
      <van-cell :value="'订货单号：'+orderNo" />
      <van-cell :value="'订货时间：'+createTime" />
    </van-cell-group>
    <van-cell title="订单状态" :value="orderStatusName" />
    <van-cell title="支付方式" :value="payTypeText" />
  </div>
</template>

<script>

import {getOrderInfoByOrderNo} from "@/api/order";

export default {
  name: "OrderDetail",
  data() {
    return {
      orderNo: '',
      createTime: '',
      payTypeText: '',
      orderStatusName: '',
      isReplaceFlag: false,
      payAmount: 0,
      replaceDetailList: [],
      targetDetailList: [],
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.orderNo = this.$route.query.orderNo;
      getOrderInfoByOrderNo(this.orderNo).then(r => {
        // console.log(r);
        let data = r.data;
        console.log(data);
        let orderType = data.orderType;
        // 换货
        if(orderType == 4 || orderType == 5 || orderType == 6) {
          this.isReplaceFlag = true;
          let detailList = eval ("(" + data.detail + ")");
          this.replaceDetailList = detailList.replaceCommodityList;
          this.targetDetailList = detailList.targetCommodityList;
          console.log(this.replaceDetailList);
          console.log(this.targetDetailList);
        } else {
          this.isReplaceFlag = false;
          this.targetDetailList = eval ("(" + data.detail + ")");
          console.log(this.targetDetailList);
        }
        this.createTime = data.createTime;
        this.payAmount = data.payAmount;
        if(data.payType) {
          if(data.payType == 1) {
            this.payTypeText = '现金';
          } else if(data.payType == 2) {
            this.payTypeText = '扫码';
          } else if(data.payType == 3) {
            this.payTypeText = '换货';
          }
        }
        this.orderStatusName = this.orderStatusFilter(data.orderStatus);
        if(data.returnStatus == 1){
                this.orderStatusName = this.orderStatusName + "（退货申请中）";
        }
      });
    },
    orderStatusFilter(val) {
      // 0待审核1订单取消2审核通过3审核驳回4确认送货5确认收货6送货超时
      const statusMap = {
        '0': '待审核',
        '1': '订单取消',
        '2': '审核通过',
        '3': '审核驳回',
        '4': '确认送货',
        '5': '确认收货',
        '6': '送货超时'
      }
      return statusMap[val]
    },
    onReturn() {
      this.$router.go(-1);
    }
  }
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.heji {
  font-size: 16px;
}
.heji .van-cell__value {
  color: orangered;
}
.van-cell__value--alone {
  color: #7b7b88;
}
</style>
